import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"font-weight-bold display-1 mb-6"},[_c('span',[_vm._v(_vm._s(_vm.$t("settings.title")))])]),_c(VCardText,[_c('div',{staticClass:"settings-item align-start mb-4"},[_c('div',{staticClass:"setting-title"},[_vm._v(_vm._s(_vm.$t("settings.pfp")))]),_c(VSpacer),_c('PfpController')],1),_c('div',{staticClass:"settings-item mb-4"},[_c('div',{staticClass:"setting-title"},[_vm._v(_vm._s(_vm.$t("settings.dark")))]),_c(VSpacer),_c('DarkModeSwitch',{attrs:{"show-label":false}})],1),(_vm.hasTrainingPermissions)?_c('div',{staticClass:"settings-item flex-column",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"setting-title"},[_vm._v(_vm._s(_vm.$t("baseline.base")))])]),_c('div',{staticClass:"d-flex mb-4 mt-8",staticStyle:{"width":"100%","justify-content":"flex-end"}},[_c('BaselineSessionUpload'),_c(VSpacer),(_vm.baseline.analyzed)?_c('div',{staticClass:"main--text"},[_c('span',{staticClass:"font-weight-bold text-h5"},[_vm._v(" "+_vm._s(_vm.baseline.pitch)+"Hz ")]),_c('span',[_vm._v(_vm._s(_vm.$t("meta.names.pitch")))])]):_vm._e(),_c('div',{staticClass:"mx-2"}),_c('div',{staticClass:"main--text"},[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.baseline.volume)+"% ")]),_c('span',[_vm._v(_vm._s(_vm.$t("meta.names.volume")))])])],1),_c('BaselineSessions'),_c(VDivider,{staticStyle:{"margin":"20px 0px"}})],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }