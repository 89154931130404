





















































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import DarkModeSwitch from "./components/DarkModeSwitch.vue";
import PfpController from "./components/PfpController.vue";
import BaselineSessions from "./components/BaselineSessions.vue";
import BaselineAnalysis from "@/core/models/sessions/BaselineAnalysis";
import BaselineSessionUpload from "./components/BaselineSessionUpload.vue";
import { Permission } from "@/core/models";

@Component({
  components: {
    PfpController,
    DarkModeSwitch,
    BaselineSessions,
    BaselineSessionUpload,
  },
})
export default class Settings extends Vue {
  @Getter("profile/baseline") baseline!: BaselineAnalysis;
  @Getter("profile/permissions") permissions!: Permission[];
  get hasTrainingPermissions() {
    return this.permissions?.some(x => x.type === "session_training");
  }
}
